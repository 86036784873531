const user = "kaiofelipejs";

export const links = [
	{
		label: "Github",
		url: `https://github.com/SzymonFialkowski`,
	},
	{
		label: "Linkedin",
		url: `https://www.linkedin.com/in/szymon-fiałkowski-ab049355/`,
	},
	// {
	// 	label: "Twitter",
	// 	url: `https://www.twitter.com/${user}`,
	// },
	{
		label: "Instagram",
		url: `https://www.instagram.com/fialkow_ski`,
	},
	// {
	// 	label: "Medium",
	// 	url: `https://www.medium.com/@${user}`,
	// },
];

export const email = "szymon@fialkow.ski";
