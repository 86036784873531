import React, {useEffect, useState} from 'react';
import {Button, GlobalStyle} from '@react95/core';
import { useClippy } from '@react95/clippy';

import logo from './windows95_logo.png';
import {MenuBar} from "./Components/MenuBar";
import {DirectoryModal} from "./Components/DirectoryModal";
import styled, { createGlobalStyle } from '@xstyled/styled-components';
import { FileModal } from './Components/FileModal';

const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
);

const Title = styled.h1`
	font-size: 40px;
	width: 100%;
	text-align: center;
`;

const SubtitleWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const Subtitle = styled.h3<{ steps: any, delay: any }>`
    display: block;
	width: 100%;
	text-align: center;
	animation: blinkCursor 900ms steps(${({ steps }: any) => steps }) infinite normal,
		typing 2s steps(${({ steps }) => steps }) 1s normal both;
    animation-delay: ${({ delay }) => delay }s;

	border-right: 2px solid rgba(0, 0, 0, 0.75);
	text-align: center;
	margin: 0 auto;
	white-space: nowrap;
	overflow: hidden;

	@keyframes typing {
		from {
			width: 0;
		}
		to {
			width: 7.5rem;
		}
	}

	@keyframes blinkCursor {
		from {
			border-right-color: rgba(0, 0, 0, 0.75);
		}
		to {
			border-right-color: transparent;
		}
	}
`;

const Subtitle2 = styled(Subtitle)`
  animation: blinkCursor 900ms steps(${({ steps }: any) => steps }) infinite normal,
  typing2 4s steps(${({ steps }) => steps }) 1s normal both;
  margin-top: 6px;
  
  @keyframes typing2 {
    from {
      width: 0;
    }
    to {
      width: 14rem;
    }
  }
`

const Slogan = styled.h4`
  position: fixed;
  font-size: 26px;
  bottom: 36px;
  right: 2px;
  margin: 0px;
`

const Style = createGlobalStyle`
  body {
    font-size: 12px;
  }

  p {
	  line-height: 1.58; 
  }
`;



function App() {
  const { clippy } = useClippy();

  const [files, setFiles] = useState({});
  const [selectedFile, setSelectedFile] = useState({});
  const [loading, toggleLoading] = useState(false);
  const [showDirectoryModal, setShowDirectoryModal] = useState(true);

  const openModal = () => {
    setShowDirectoryModal(true);
  }

  const closeModal = () => {
    setShowDirectoryModal(false);
  }

  useEffect(() => {
    if (clippy) {
      clippy.play('GetAttention');
    }
  }, [clippy]);

  return (
   <>
     <GlobalStyle />
     <Style />
     <Title>
       Hello!{" "}
       <span role="img" aria-label="waving hand">👋🏽</span>
     </Title>
     <SubtitleWrapper>
       <Subtitle delay={0} steps={35}>
         Szymon Fialkowski
       </Subtitle>

       <Subtitle2 delay={30} steps={35}>
         ReactJS & Angular & AWS & Web3
       </Subtitle2>
     </SubtitleWrapper>

     <FileModal
         selectedFile={selectedFile}
         setSelectedFile={setSelectedFile}
         isMobile={isMobile}
     />

     {showDirectoryModal && <DirectoryModal close={closeModal}
                                            openModal={openModal}
                                            setSelectedFile={setSelectedFile}
                                            isMobile={isMobile} />}



       <Slogan>LetsDevelop.IT together!</Slogan>
      <MenuBar />
    </>
  );
}

export default App;
