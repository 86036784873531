import styled from "@xstyled/styled-components";
import React from "react";


export const StyledFile = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	text-align: center;

	width: 25%;
	padding: 10px 0;
`;

export const Name = styled.span`
	word-break: break-word;
`;

export const FileButton = ({ filename, icon, ...rest }: any) => (
    <StyledFile {...rest}>
        {icon}
        <Name>{`${filename}`}</Name>
    </StyledFile>
);
