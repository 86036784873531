import React from "react";
import { Modal, Frame } from "@react95/core";

import Career from "../Containers/Career";
import About from "../Containers/About";
import Projects from "../Containers/Projects";
import Contact from "../Containers/Contact";
import Posts from "../Containers/BlogPosts";

export const FileModal = ({ selectedFile, setSelectedFile, isMobile }: any) => {
    function renderContent(selectedFile: { name: string }) {
        switch (selectedFile.name) {
            case "About":
                return <About />;
            case "Projects":
                return <Projects />;
            case "Career":
                return <Career />;
            case "Blog posts":
                return <Posts />;
            default:
                return <Contact />;
        }
    }

    const closeModal = () => setSelectedFile(null);

    return selectedFile?.name && (
        <Modal
            icon={selectedFile.icon}
            title={selectedFile.name}
            closeModal={closeModal}
            defaultPosition={isMobile
                ? { x: 0, y: 20 }
                : { x: window.innerWidth / 2 - 350, y: 20 }}
            style={isMobile ? { width: "100%", height: "auto", zIndex: "999" } : { width: "700px", zIndex: 999 }}
            buttons={[{ value: "Close", onClick: closeModal }]}
        >
            <Frame
                bg="white"
                boxShadow="in"
                p={10}
                style={{
                    overflowY: "auto",
                    maxHeight: "77vh",
                }}
            >
                {renderContent(selectedFile)}
            </Frame>
        </Modal>
    );
};
