export const resume = [
	{
		companyName: "Roger Publishing",
		job: "Software Engineer",
		description:
			`Build team & high scalability application for fast growing, successful startup from scratch (AWS, React, Gatsby, Graphql, Saleor).\n
            Technical support for the founder.`,
		dates: {
			startMonth: "May",
			startYear: "2019",
			endMonth: "",
			endYear: "",
		},
	},
	{
		companyName: "Stepwise",
		job: "Software Engineer",
		description:
			`Web portal for Ministry of Education.\n
            Technologies: Angular4, Redux`,
		dates: {
			startMonth: "October",
			startYear: "2017",
			endMonth: "May",
			endYear: "2019",
		},
	},
	{
		companyName: "Madiff",
		job: "Angular Developer",
		description:
			"",
		dates: {
			startMonth: "March",
			startYear: "2017",
			endMonth: "",
			endYear: "",
		},
	},
	{
		companyName: "New Media Ventures",
		job: "Front-end Developer",
		description:
			"",
		dates: {
			startMonth: "September",
			startYear: "2014",
			endMonth: "June",
			endYear: "2016",
		},
	},
    {
        companyName: "Fiema Studio",
        job: "Full-stack developer",
        description:
            `Wrote truly responsive Mobile First websites based on AngularJS\n
Created efficient, semantic code\n
Cross­browser with backward compatibility for IE8\n
Responsive HTML and emails for creative entertainment agencies\n
Maintain knowledge of email and spam filter trends\n
Developed maps application using jQuery and Google Maps API v3`,
        dates: {
            startMonth: "September",
            startYear: "2012",
            endMonth: "October",
            endYear: "2014",
        },
    },
    {
        companyName: "Freelancer",
        job: "Web Developer",
        description:
            `Worked with several agencies including FlyPR, Supremum 360, Perfect Media,\n
Order Group\n
Delivered websites in Open source CMS such as Wordpress, Joomla, Drupal,\n
eCommerce ­PrestaShop.\n
Developed my own CMS\n
Online payment services integration`,
        dates: {
            startMonth: "September",
            startYear: "2012",
            endMonth: "",
            endYear: "",
        },
    },
];

export const education = [
	{
		schoolName: "Academy of Science and Technology",
		degree: "",
		fieldStudy: "IT",
		description: "",
		dates: {
			startYear: "2013",
			endYear: "",
		},
	},
];

export const courses = [
	// {
	// 	name: "Ruby I: Introdução a Lógica de Programação com Jogos",
	// 	organization: "Alura Cursos Online",
	// 	certificateUrl:
	// 		"https://cursos.alura.com.br/certificate/458301d8-2c3c-45af-98b7-64d76e6f0607",
	// 	issueDate: {
	// 		month: "March",
	// 		year: "2020",
	// 	},
	// },
];
