import React, {useEffect, useState} from "react";
import styled from "@xstyled/styled-components";
import { Frame, Modal } from "@react95/core";
import {Bookmark, FileText, Folder, Mail, WindowsExplorer} from "@react95/icons";
import { FileButton } from "./FileButton";

const files = [
    {
        name: "About",
        icon: <Bookmark />
    },
    {
        name: "Projects",
        icon: <Folder />,
    },
    {
        name: "Career",
        icon: <FileText />,
    },
    {
        name: "Contact",
        icon: <Mail />,
    },
    // {
    // 	name: "Blog posts",
    // 	icon: "file_pen",
    // },
];

const Wrapper = styled.div`
	display: flex;
	grid-template-columns: repeat(4, 1fr);
	flex-wrap: wrap;
`;

export const DirectoryModal = ({ close, openModal, setSelectedFile, isMobile }: any) => {

    const openFile = () => {}

    return (
        <Modal
            title={`${files.length} files`}
            icon={<WindowsExplorer variant="32x32_4" />}
            menu={[]}
            closeModal={close}
            defaultPosition={isMobile
                ? { x: 0, y: 150 }
                : { x: window.innerWidth / 2 - 250, y: 150 }}
            style={isMobile ? { width: "100%", height: "auto" } : { width: "500px" }}
        >
            <Frame
                bg="white"
                boxShadow="in"
                p={10}
                style={{
                    overflowY: "auto",
                    height: "100%"
                }}
            >
                <Wrapper>
                    {files.map((file) => (
                        <FileButton
                            key={file.name}
                            onClick={() => {
                                setSelectedFile(file);
                                openFile();
                            }}
                            filename={file.name}
                            icon={file.icon}
                        />
                    ))}
                </Wrapper>
            </Frame>
        </Modal>
    );
};

